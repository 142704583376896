import { ThemeContext } from "@emotion/react";
import { Button, FormControl, FormLabel, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { TablaActivos } from "./tabla_activos";
import { GetOrdenesNew } from "../../../../hooks/getOrdenes";
import { TipoDocumentoEnum } from "../../../../@enums/tipo_documento";
import { EstatusDocumentoEnum } from "../../../../@enums/status_documento.enum";
import { CargarSalida } from "../../../../hooks/cargarSalida";
import { TablaEnviados } from "./tabla_enviados";
import { useSnackbar } from "notistack";
import { Search, SearchOutlined } from "@mui/icons-material";

export const Sections = ({ create, setCreate, setOrden, orden }) => {
	const { enqueueSnackbar } = useSnackbar();
	const theme = useContext(ThemeContext);
	const { getOrdenesNewMutation } = GetOrdenesNew();
	const { cargarSalidaMutation } = CargarSalida();
	const [seccionId, setSeccionId] = useState(1);
	const [activos, setActivos] = useState([]);
	const [enviados, setEnviados] = useState([]);
	const [filtro, setFiltro] = useState('');
	const [inicio, setInicio] = useState(null);
	const [end, setEnd] = useState(null);
	const inputRef = useRef(null);

	const btn_seccion = [
		{
			id: 1,
			name: "Abiertas",
			component: (
				<TablaActivos
					key={"tabla_activos"}
					sample={activos}
					setOrden={setOrden}
				/>
			),
		},
		{
			id: 2,
			name: "Enviadas",
			component: (
				<TablaEnviados
					key={"tabla_enviados"}
					sample={enviados}
					setOrden={setOrden}
				/>
			),
		},
	];

	const get_ordenes_activas = () => {
		let where = {
			docu_tipo: TipoDocumentoEnum.SALIDA,
			docu_estatus: EstatusDocumentoEnum.SIN_ESTATUS,
		};
		let relations = { empresas: true };
		getOrdenesNewMutation(
			{ where: where, relations: relations, clave: '' },
			{
				onSuccess: (data) => {
					setActivos(data);
				},
				onError: (error) => {
					console.log(error);
				},
			}
		);
	};

	const get_ordenes_enviadas = () => {
		let where = {
			docu_tipo: TipoDocumentoEnum.SALIDA,
			docu_estatus: EstatusDocumentoEnum.CERRADO,
		};
		let relations = { empresas: true };
		getOrdenesNewMutation(
			{ where: where, relations: relations, clave: filtro, fecha_inicial: inicio, fecha_final: end },
			{
				onSuccess: (data) => {
					setEnviados(data);
				},
				onError: (error) => {
					console.log(error);
				},
			}
		);
	};

	
	const handleInputChange = (event) => {
		setFiltro(event.target.value);
	};
	const handleKeyDown = (event) => {
		get_ordenes_enviadas();
	};

	useEffect(() => {
		get_ordenes_activas();
	}, []);

	useEffect(() => {
		if (create) {
			get_ordenes_activas();
			setCreate(false);
		}
	}, [create]);

	useEffect(() => {
		if (orden === null) {
			get_ordenes_activas();
		}
	}, [orden]);

	const changeSeccion = (id) => {
		id === 1 ? get_ordenes_activas() : setEnviados([]);
		setSeccionId(id);
	};

	const cargar_salida = (tag) => {
		cargarSalidaMutation(tag, {
			onSuccess: (data) => {
				inputRef.current.value = "";
				inputRef.current.focus();
				if (!data || !data?.success) {
					enqueueSnackbar(!data?.mensaje ? "Etiqueta Invalida" : data?.mensaje, {
						autoHideDuration: 2000,
						variant: "error",
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
					});
				} else if (data?.success) {
					enqueueSnackbar("Etiqueta agregada", {
						autoHideDuration: 2000,
						variant: "success",
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
					});
				}
			},
			onError: (error) => {
				console.log(error);
				inputRef.current.value = "";
				inputRef.current.focus();
				enqueueSnackbar("Error al leer la etiqueta", {
					autoHideDuration: 2000,
					variant: "error",
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
				});
			},
		});
	};

	return (
		<Grid
			container
			spacing={0}
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Grid
				item
				xs={12}
				sm={12}
				md={6}
				lg={ seccionId === 2 ? 8 : 4 }
				xl={ seccionId === 2 ? 8 : 4 }
				sx={{ p: 1 }}
				className="center_item"
			>
				<Grid container spacing={0}>
					{btn_seccion.map((btn) => (
						<Grid
							key={btn.name}
							item
							xs={seccionId === 2 ? 2 : 6}
							sx={{ p: 1 }}
							className="center_item"
						>
							<Button
								variant="contained"
								className="btn_square"
								key={btn.id}
								sx={
									seccionId === btn.id
										? {
												color: theme.palette.primary.contrastText,
												bg: theme.palette.primary.main,
												fontSize: 12,
										  }
										: {
												color: theme.palette.primary.title,
												bgcolor: theme.palette.secondary.btn,
												fontSize: 12,
										  }
								}
								onClick={() => changeSeccion(btn.id)}
								disableElevation
							>
								{btn.name}
							</Button>
						</Grid>
					))}
					{
						seccionId === 2 && (
							<>
								<Grid
								key='grid_search'
								item
								xs={2}
								sx={{ p: 1 }}
								className="center_item"
								>
									<TextField
									id="search_entry"
									label="Palabra clave"
									fullWidth
									name="search_entry"
									size="small"
									value={filtro}
									onChange={handleInputChange}
									sx={{ color: theme.palette.primary.input }}
									/>
								</Grid>
								<Grid
								key='grid_search'
								item
								xs={2}
								sx={{ p: 1 }}
								className="center_item"
								>
									<FormControl variant="outlined"  fullWidth>
										<TextField
											id='filtro_start'
											type="date"
											fullWidth
											size="small"
											value={inicio}
											label='Inicio'
											name="filtro_start"
											hiddenLabel
											placeholder=""
											sx={{color: theme.palette.primary.input}}
											onChange={(e)=>setInicio(e.target.value)}
											className="input-date_salida"
											InputLabelProps={{
												shrink: true,
											}}
								
											/>
									</FormControl>
								</Grid>
								<Grid
								key='grid_search'
								item
								xs={2}
								sx={{ p: 1 }}
								className="center_item"
								>
									<FormControl variant="outlined"  fullWidth>
										<TextField
											id='filtro_end'
											type="date"
											fullWidth
											size="small"
											value={end}
											label='Fin'
											name="filtro_end"
											hiddenLabel
											placeholder=""
											sx={{color: theme.palette.primary.input}}
											onChange={(e)=>setEnd(e.target.value)}
											className="input-date_salida"
											InputLabelProps={{
												shrink: true,
											}}
								
											/>
									</FormControl>
								</Grid>
								<Grid
								key='grid_search'
								item
								xs={1}
								sx={{ p: 1 }}
								className="center_item"
								>
									<IconButton 
									variant="contained" 
									sx={{
										p:1, 
										backgroundColor: '#575E3B', 
										borderRadius: 2, 
										color: 'white', 
										width: '100%'
									}}  
									onClick={handleKeyDown}>
											<SearchOutlined />
									</IconButton>
								</Grid>
							</>
							
						)
					}
					
				</Grid>
				
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={3}
				lg={3}
				xl={3}
				sx={{ p: 2, gap: 2 }}
				className="center_item"
			>
				<TextField
					id="tag"
					label="Etiqueta"
					fullWidth
					size="small"
					inputRef={inputRef}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							cargar_salida(e.target.value);
						}
					}}
					sx={{ color: theme.palette.primary.input }}
				/>
			</Grid>
			<Grid item xs={12} sx={{ p: 1, height: "75vh" }} className="center_item">
				{btn_seccion[seccionId - 1]?.component}
			</Grid>
		</Grid>
	);
};
