import React, { useEffect, useState } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { GetDocumentos } from "../../../hooks/getDocumentosByTipo";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HEADER_DATA_UPDATE } from "../../../redux/reducers/header.reducer";
import TablaEntradas from "./tablaEntradas";

const EntradasIndex = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {getDocumentosMutation} = GetDocumentos();
    const [docs, setDocs] = useState([]);
    const [filtro, setFiltro] = useState('');

    const goNuevo = () => {
        navigate(`/inventarios/entradas/agregar`);
    }

    const get_docs = () => {
        getDocumentosMutation({tipo:1, clave: filtro}, {
            onSuccess: (data) => {
                setDocs(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        dispatch(
            HEADER_DATA_UPDATE({nombreData: null})
        )
    }, []);

    const handleInputChange = (event) => {
        setFiltro(event.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            get_docs()
        }
	};

    return(
        <>
            <Grid container spacing={0} sx={{height:'10vh'}}>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{padding:'10px 30px', height:'100%'}} className="center_item">
                    <Button variant="contained" className="btn_system" onClick={goNuevo}>
                        + Nueva entrada
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={0} sx={{height:'80vh'}} >
                <Grid item xs={12} sx={{padding:'10px 20px', height:'100%'}} className="center_item">
                    <TablaEntradas  key={'entries_table'} sample={docs} filtro={filtro} handleInputChange={handleInputChange} onPressEnter={handleKeyDown}/>
                </Grid>
            </Grid>
        </>
    )
}

export default EntradasIndex;