import React, { useEffect, useState } from "react";
import {Divider, Grid } from "@mui/material";
import HeaderPanelControl from "./header";
import TableProductsPanel from "./table_products";
import TableProcesosPanel from "./table_procesos";
import Reportar from "./components/reportar";
import ModalAlmacenes from "./components/modalAlmacenes";
import ModalCarga from "./components/modales/modalCharge";
import { GetAllAlmacenesList } from "../../../hooks/getListAlmacen";
import { GetMovimientosByAlmacen, GetMovimientosByAlmacenCargados, GetMovimientosByAlmacenProcesados } from "../../../hooks/getMovimientosByAlmacen";
import TablaGeneral from "./components/table_general";
import AyudasVisuales from "./components/ayudasVisuales";
import { EstatusMovimientoEnum } from "../../../@enums/status_mov_inv";
import array_first_seen from "../../../helpers/arrayFirstSeen";
import { AreaAlmacenEnum } from "../../../@enums/tipo_almacen";
import { ModalSplit } from "./components/modales/modalSplit";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { NoAccess } from "../../errors/no_access";
import { ModalEtiquetas } from "./components/modales/modalEtiquetas";
import { ModalMerge } from "./components/modales/modalMerge";

const selectPermission = (state) => state.auth.permissions;
const selectPermissions = createSelector(
  [selectPermission],
  (permissions) => ({ permissions })
);

const PanelDeControlIndex = () => {


    const { permissions } = useSelector(selectPermissions);
    const {getAllAlmacenesListMutation} = GetAllAlmacenesList();
    const {getMovimientosByAlmacenMutation} = GetMovimientosByAlmacen();
    const {getMovimientosByAlmacenCargadosMutation} = GetMovimientosByAlmacenCargados();
    const {getMovimientosByAlmacenProcesadosMutation} = GetMovimientosByAlmacenProcesados();
    const [seccionId, setSeccionId] = useState(null);
    const [openModal, setOpenModal] = useState(false)    
    const [almacenes, setAlmacenes] = useState([])
    const [movGen, setMovGen] = useState([]);
    const [movCar, setMovCar] = useState([]);
    const [movPros, setMovPros] = useState([]);
    const [openAyudas, setOpenAyudas] = useState(false);
    const [ayudasVisuales, setAyudasVisuales] = useState([]);
    const [openCargas, setOpenCargas] = useState(false);
    const [isCharge, setIsCharge] = useState(false);
    const [optionSelect, setOptionsSelect] = useState([]);
    const [nameAlm, setNameAlm] = useState('');
    const [totalPros, setTotalPros] = useState(0);
    const [isReporting, setIsReporting] = useState(false);
    const [movReporting, setMovReporting] = useState(null);
    const [saveRepor, setSaveReport] = useState(false);
    const [canReport, setCanReport] = useState(true);
    const [openSplit, setOpenSplit] = useState(false);
    const [noAccess, setNoAccess] = useState(false);
    const [openReprint, setOpenReprint] = useState(false);
    const [openMerge, setOpenMerge] = useState(false);
    const [filtro, setFiltro] = useState('');

    function getMovimientos(id){
        getMovimientosByAlmacenMutation({id:id, status:EstatusMovimientoEnum.ACTIVO, clave: filtro},{
            onSuccess: (x) => {
                setMovGen(x);
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }
    function get_Cargados (id){
        getMovimientosByAlmacenCargadosMutation({id:id, clave: ''},{
            onSuccess: (y) => {
                const option = y.map(item => {
                    return {
                        id: item?.part_id,
                        label: item?.codigo
                    }
                })
                setOptionsSelect(option)
                setMovCar(y);
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }
    function get_procesados (id){
        getMovimientosByAlmacenProcesadosMutation({id:id},{
            onSuccess: (z) => {
              //Procesar activos
                let data_orden = array_first_seen(z?.data);
                let data_to_array = {};
                data_orden?.forEach(objeto => {
                  if (!data_to_array[objeto?.moin_agrupadorprocesado]) {
                    data_to_array[objeto?.moin_agrupadorprocesado] = {
                        codigo: objeto?.codigo,
                        moin_agrupadorprocesado: objeto?.moin_agrupadorprocesado,
                        acciones: objeto?.acciones,
                        nombre: objeto?.nombre,
                        almacen: objeto?.almacen,
                        cues_id: objeto?.cues_id,
                        cuestionario: objeto?.cuestionario,
                        part_id: objeto?.part_id,
                        datos: [],
                        activo: true
                    };
                  }
                  
                  // Añadimos el objeto serial, etiqueta y cantidad al array de datos
                  data_to_array[objeto?.moin_agrupadorprocesado].datos.push({
                    serial: objeto?.serial,
                    tag: objeto?.tag,
                    tag_id: objeto?.tag_id,
                    existencia_total: objeto?.existencia_total,
                    moin_id: objeto?.moin_id
                  });
                });
                const data_grouped = Object.values(data_to_array);

                //Procesar inactivos
                let data_inc = array_first_seen(z?.data_inactive)
                let data_to_array_inc = {}
                data_inc?.forEach(objeto => {
                  if (!data_to_array_inc[objeto?.moin_agrupadorprocesado]) {
                    data_to_array_inc[objeto?.moin_agrupadorprocesado] = {
                        codigo: objeto?.codigo,
                        moin_agrupadorprocesado: objeto?.moin_agrupadorprocesado,
                        acciones: objeto?.acciones,
                        nombre: objeto?.nombre,
                        almacen: objeto?.almacen,
                        cues_id: objeto?.cues_id,
                        cuestionario: objeto?.cuestionario,
                        part_id: objeto?.part_id,
                        datos: [],
                        activo:false
                    };
                  }
                  
                  // Añadimos el objeto serial, etiqueta y cantidad al array de datos
                  data_to_array_inc[objeto?.moin_agrupadorprocesado].datos.push({
                    serial: objeto?.serial,
                    tag: objeto?.tag,
                    tag_id: objeto?.tag_id,
                    existencia_total: objeto?.existencia_total,
                    moin_id: objeto?.moin_id
                  });
                });
                const data_grouped_inc = Object.values(data_to_array_inc);

                let data_total = data_grouped.concat(data_grouped_inc);

                let agrupados = new Set();

                data_total.forEach(objeto => {
                  agrupados.add(objeto.moin_agrupadorprocesado);
                });
                setMovPros(data_total);
                setTotalPros(agrupados?.size);
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const filter_almacenes_permissions = (data) => {
      const patron = /^MRP:Administracion:Almacenes:(\d+)$/;
      const numerosFinales = permissions.reduce((acumulador, objeto) => {
        const match = objeto.permissionKey.match(patron);
        if (match) {
          acumulador.push(Number(match[1]));
        }
        return acumulador;
      }, []);
      const numerosSet = new Set(numerosFinales);
      const almacenesFiltrados = data.filter(almacen => numerosSet.has(almacen.id));
      if(almacenesFiltrados.length > 0){
        setCanReport(
          almacenesFiltrados[0]?.area === AreaAlmacenEnum.LOGISTICA 
          ? false : true
        )
        setAlmacenes(almacenesFiltrados)
        setSeccionId(almacenesFiltrados[0]?.id)
        setNameAlm(almacenesFiltrados[0]?.label?.toUpperCase())
      }else{
        setNoAccess(true)
      }
    } 

    const get_list = () => {
        getAllAlmacenesListMutation({},{
            onSuccess: (data) => {
                filter_almacenes_permissions(data)
            },
            onError: (error) => {
                console.log(error)
            }
        })
    }

    const handleInputChange = (event) => {
      setFiltro(event.target.value);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getMovimientos(seccionId);
        }
    };

    useEffect(() => {
        get_list();
    }, []);

    useEffect(() => {
        get_Cargados(seccionId);
        get_procesados(seccionId);
        if(canReport){
          getMovimientos(seccionId);
        }else{
          setMovGen([])
        }
    }, [seccionId]);

    const openProceso = (isCharg) => {
        setIsCharge(isCharg);
        setOpenCargas(true)
    }

    const btn_seccion = [
        {
            id: 1,
            name: 'Cargar',
            disabled : !canReport,
            function: () => openProceso(true)
        },
        {
            id: 2,
            name: 'Descargar', 
            disabled : !canReport,
            function: () => openProceso(false)
        },
        {
            id: 3,
            name: 'Master',
            disabled : false,
            function: () => setOpenSplit(true)
        },
        {
            id: 4,
            name: 'Etiquetas',
            disabled : false,
            function: () => setOpenReprint(true)
        },
        {
            id: 5,
            name: 'Merge/Split',
            disabled : false,
            function: () => setOpenMerge(true)
        },
    ]

    return(
        <>
            { noAccess ? 
              (
                <>
                  <NoAccess key={'no_access_almacenes'}/>
                </>
              ):
              (
                <>
                  <HeaderPanelControl key='head_panel' btn_seccion={btn_seccion} setOpen={setOpenModal} nameAlm={nameAlm} total={totalPros} isReporting={isReporting} movimiento={movReporting} setIsReporting={setIsReporting} setMovReporting={setMovReporting} setSaveReport={setSaveReport} alm={seccionId} getMovimientos={getMovimientos} get_Cargados={get_Cargados} get_procesados={get_procesados}/>
                  <Divider/>
                  {isReporting ? (
                      <Grid container spacing={0}>
                          <Reportar key='module_reportat' movimiento={movReporting} setMovReporting={setMovReporting} saveReport={saveRepor} setSaveReport={setSaveReport} setIsReporting={setIsReporting} seccionId={seccionId} getMovimientos={getMovimientos} get_Cargados={get_Cargados} get_procesados={get_procesados} alm={seccionId} />
                      </Grid>
                  ):(
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={openAyudas ? 10:12} lg={openAyudas ? 10:12} xl={openAyudas ? 10:12} sx={{p:1, alignItems:'flex-start'}} className="center_item">
                          <Grid container spacing={0}>
                              <Grid item xs={12} sm={12} md={canReport ? 6 : 12} lg={canReport ? 7 : 12} xl={canReport ? 7 : 12} className="center_item" sx={{p:1, justifyContent:'flex-start', flexDirection:'column', gap:2, mb:2}}>
                                  {
                                    canReport && 
                                    <TableProductsPanel 
                                      key={'table_primary'} 
                                      sample={movCar}
                                      almacen={seccionId}
                                      updateCargados={get_Cargados}
                                      updateGeneral={getMovimientos}
                                    />
                                  }

                                  <TablaGeneral 
                                      key={'table_gen_mov_alm'} 
                                      sample={movGen} 
                                      setAyudasVisuales={setAyudasVisuales}
                                      setOpenAyudas={setOpenAyudas}
                                      almacen={seccionId}
                                      updateCargados={get_Cargados}
                                      updateGeneral={getMovimientos}
                                      onPressEnter={handleKeyDown}
                                      handleInputChange={handleInputChange}
                                      filtro={filtro}
                                      canSearch={!canReport}
                                  />
                              </Grid>
                              {
                                canReport && 
                                <Grid item xs={12} sm={12} md={6} lg={5} xl={5} className="center_item" sx={{p:1, alignItems:'flex-start'}}>
                                  <TableProcesosPanel 
                                      key={'table_process'} 
                                      sample={movPros} 
                                      codes={optionSelect}
                                      almacen={seccionId}
                                      movimientos={movCar}
                                      get_cargados={get_Cargados}
                                      get_procesados={get_procesados}
                                      setIsReporting={setIsReporting}
                                      setMovReporting={setMovReporting}
                                      canReport={canReport}
                                  />
                                </Grid>
                              }
                          </Grid>             
                      </Grid>
                      <Grid item xs={12} sm={12} md={openAyudas ? 2:0} lg={openAyudas ? 2:0} xl={openAyudas ? 2:0} sx={{p:1}}>
                          {
                              openAyudas && 
                              <AyudasVisuales
                              key={'ayudas_visuales_gen'}
                              ayudas={ayudasVisuales}
                              setAyudas={setAyudasVisuales}
                              setOpenAyudas={setOpenAyudas}/>
                          }
                      </Grid>
                    </Grid>
                  )}
                </>
              )
            }
            <ModalAlmacenes 
            key={'modal_alm'} 
            open={openModal} 
            setOpen={setOpenModal}
            setSeccionId={setSeccionId}
            almacenes={almacenes}
            setAyudas={setAyudasVisuales}
            setOpenAyudas={setOpenAyudas}
            setName={setNameAlm}
            setCanReport={setCanReport}
            />
            <ModalCarga
            key={'modal_cargas'}
            open={openCargas}
            setOpen={setOpenCargas}
            isCharge={isCharge}
            almacen={seccionId}
            getCargados={get_Cargados}
            getMovimiento={getMovimientos}
            />
            <ModalSplit
            key={'modal_split'}
            open={openSplit}
            setOpen={setOpenSplit}
            almacen={seccionId}
            />
            <ModalEtiquetas
            key={'modal_etiquetas'}
            open={openReprint}
            setOpen={setOpenReprint}
            almacen={seccionId}
            get_movimientos={getMovimientos}/>
            <ModalMerge
            key={'modal_merge_main'}
            open={openMerge}
            setOpen={setOpenMerge}
            almacen={seccionId}/>
        </>
    )
}

export default PanelDeControlIndex;