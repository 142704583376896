import React, { useContext, useState } from "react";
import { Avatar, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Toolbar, Typography } from "@mui/material";
import { Menu, KeyboardArrowRight, Home, Logout } from "@mui/icons-material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import styled from "@emotion/styled";
import { ThemeContext } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import capitalizeFirstLetter from "../helpers/mayusInit";
import logo from '../views/resources/imgs/logo_sidebar.svg'
import { createSelector } from "reselect";
import { AUTH_LOGOUT } from "../redux/reducers/auth.reducer";
import { REACT_APP_ENVIRONMENT } from "../env";

const selectUser = (state) => state.auth.user;
const selectedUser = createSelector(
  [selectUser],
  (user) => ({ user })
);

const AppBarComponent = ({menuOptions}) => {

    const theme_global = useContext(ThemeContext)
    const location = useLocation();
    const dispatch = useDispatch();
    const text_header =  location?.pathname.split('/').filter(item => item !== '');
    const header = useSelector((state) => state.header);
    const { user } = useSelector(selectedUser);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const drawerWidth = 240;

    const openedMixin = (theme) => ({
        width: drawerWidth,
        background: 'linear-gradient(192.31deg, #3B402A 59.5%, #5E5B3B 100%) !important',
        color: theme_global.palette.primary.contrastText,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });
    
    const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    background: 'linear-gradient(192.31deg, #3B402A 59.5%, #5E5B3B 100%) !important',
    color: theme_global.palette.primary.contrastText,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2, 1),
        background: theme_global.palette.secondary.main,
        color: theme_global.palette.primary.contrastText,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        background: '#ffffff',
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    
    const handleDrawerClose = () => {
        setOpen(false);
    };

    //AVATAR

    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: theme_global.palette.primary.main,
            mr: 2
          },
          children: `${name?.[0]?.toUpperCase()}`,
        };
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openAvatar = Boolean(anchorEl);
    const id = openAvatar ? 'simple-popover' : undefined;

    const closeSession = () => {
      dispatch(
        AUTH_LOGOUT()
      )
    }


    return(
        <>
          <AppBar position="fixed"  open={open}>
              <Toolbar>
                  <IconButton
                      aria-label="open drawer"
                      onClick={ open ? handleDrawerClose : handleDrawerOpen}
                      edge="start"
                      sx={{
                          color:'#B6B6B6',
                          marginRight: 5,
                      }}
                  >
                      <Menu />
                  </IconButton>
                  <Typography variant="h6" noWrap component="div" sx={{color: theme_global.palette.primary.title}}>
                      {capitalizeFirstLetter(text_header[0])}
                  </Typography>
                  <>
                      <KeyboardArrowRight sx={{color: theme_global.palette.primary.title}}/>
                      <Typography variant="h6" noWrap component="div" sx={{color: theme_global.palette.primary.main}}>
                          {capitalizeFirstLetter(text_header[1])}
                      </Typography>
                  </>
                  {header.nombreData && (
                      <>
                          <KeyboardArrowRight sx={{color: theme_global.palette.primary.title}}/>
                          <Typography variant="h6" noWrap component="div" sx={{color: theme_global.palette.primary.main}}>
                              {capitalizeFirstLetter(header.nombreData)}
                          </Typography>
                      </>
                  )}	
									<div style={{
										right:0, 
										position:'fixed', 
										display: 'flex', 
										flexDirection: 'row'}}
									>
										{
											REACT_APP_ENVIRONMENT !== 'production' && 
											(
											<div
												style={{
													backgroundColor: '#FE0000', 
													padding: 5, 
													display: 'flex', 
													alignItems: 'center',
													marginRight: 10
												}}
											>
												<Typography
													sx={{fontWeight: 700}}	
												>
														Entorno de Desarrollo
												</Typography>
											</div>
											)
										}	
										<Avatar 
											{...stringAvatar(user?.username)}  
                      aria-describedby={id}
                      onClick={handleClick}
                  	/>
									</div>
                  
                  <Popover 
                      id={id}
                      open={openAvatar}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                      }}
                      transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}
                      sx={{position:'fixed', right:0,}}
                  >
                      <div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'flex-start',}}>
                        <span >{user?.username}</span>
                        <span style={{fontSize:'12px'}}>{user?.email}</span>
                      </div>
                      <Divider   sx={{mt:1, mb:2}} />
                      <div style={{display: 'flex', flexDirection:'column', marginTop:5}}>
                          <span style={{display:'flex', alignItems:'center', marginBottom:10, cursor:'pointer'}} onClick={closeSession}>
                              <Logout sx={{mr:1, color: theme_global.palette.primary.main}}/> Cerrar sesión
                          </span>
                          {/* <span style={{display:'flex', alignItems:'center'}}>
                              <Build sx={{mr:1, color: theme_global.palette.primary.main}}/>Configuración
                          </span> */}
                      </div>
                  </Popover>
              </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                  {open && (
                      <Avatar alt="logo_company" variant="rounded" sx={{ width: 60, height: 60 }} src={logo} />
                  )}
              </DrawerHeader>
              <Divider />
              <List>
                  <Link to={'/home'} style={{textDecoration:'none', color:'inherit', }} key={'redirect_home'}>
                                      <ListItem key={'home'} disablePadding sx={{ display: 'block' }}>
                                      <ListItemButton
                                              sx={{
                                              minHeight: 48,
                                              justifyContent: 'center',
                                              display:'flex'
                                              }}
                                      >
                                              <ListItemIcon
                                              sx={{
                                                      minWidth: 0,
                                                      mr: open ? 3 : 'auto',
                                                      justifyContent: 'end',
                                                      color: theme_global.palette.primary.contrastText,
                                                      width: open ? 60 : 'auto'                            
                                              }}
                                              >
                                                      <Home />
                                              </ListItemIcon>
                                              <ListItemText primary='Home' 
                                                      sx={{ opacity: open ? 1 : 0, 
                                                      display:'flex', 
                                                      justifyContent:'start',
                                                      textDecoration:'none'
                                                      }} />
                                      </ListItemButton>
                                      </ListItem>
                  </Link>
                  {menuOptions.map((option, index) => (
                      <Link to={option.redirect} style={{textDecoration:'none', color:'inherit'}} key={index}>
                          <ListItem key={option.id} disablePadding sx={{ display: 'block' }}>
                          <ListItemButton
                              sx={{
                              minHeight: 48,
                              justifyContent: 'center',
                              display:'flex'
                              }}
                          >
                              <ListItemIcon
                              sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 'auto',
                                  justifyContent: 'end',
                                  color: theme_global.palette.primary.contrastText,
                                  width: open ? 60 : 'auto'                            
                              }}
                              >
                                  {option.icon}
                              </ListItemIcon>
                              <ListItemText primary={option.name} 
                                  sx={{ opacity: open ? 1 : 0, 
                                  display:'flex', 
                                  justifyContent:'start',
                                  textDecoration:'none'
                                  }} />
                          </ListItemButton>
                          </ListItem>
                      </Link>
                  ))}
              </List>

          </Drawer>
        </>
    )
}

export default AppBarComponent;